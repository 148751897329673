import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppContext from 'contexts/AppContext'
import Header from 'components/layout/Header'
import Page from 'components/layout/Page'
import Footer from 'components/layout/Footer'
import styled from 'styled-components'

const App = () => {
  const [useWebp, setUseWebp] = useState(false)

  useEffect(() => {
    // Check webp compatibility
    var webpTests = [{
      'uri': 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=',
      'name': 'webp'
    }, {
      'uri': 'data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAABBxAR/Q9ERP8DAABWUDggGAAAADABAJ0BKgEAAQADADQlpAADcAD++/1QAA==',
      'name': 'webp.alpha'
    }, {
      'uri': 'data:image/webp;base64,UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
      'name': 'webp.animation'
    }, {
      'uri': 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=',
      'name': 'webp.lossless'
    }]
    var webpResults = []

    var webp = webpTests.shift();
    function test(name, uri, cb) {

      var image = new Image();

      function addResult(event) {
        // if the event is from 'onload', check the see if the image's width is
        // 1 pixel (which indiciates support). otherwise, it fails
        var result = event && event.type === 'load' ? image.width === 1 : false;
        var baseTest = name === 'webp';
        webpResults.push({ name, result, baseTest })

        if (webpResults.length === webpTests.length) {
          setUseWebp(webpResults.filter(r => r.result === true).length === webpResults.length)
        }

        // if it is the base test, and the result is false, just set a literal false
        // rather than use the Boolean contrsuctor
        // addTest(name, (baseTest && result) ? new Boolean(result) : result);

        if (cb) {
          cb(event);
        }
      }

      image.onerror = addResult;
      image.onload = addResult;

      image.src = uri;
    }

    // test for webp support in general
    test(webp.name, webp.uri, function(e) {
      // if the webp test loaded, test everything else.
      if (e && e.type === 'load') {
        for (var i = 0; i < webpTests.length; i++) {
          test(webpTests[i].name, webpTests[i].uri);
        }
      }
    });

  }, [])

  return (
    <BrowserRouter>
      <AppContext.Provider value={{ useWebp }}>
        <Styled>
          <Header />
          <Page />
          <Footer />
        </Styled>
      </AppContext.Provider>
    </BrowserRouter>
  )
}

export default App

const Styled = styled.div`
display: flex;
flex-direction: column;
min-height: 100vh;

main, .main {
  flex-grow: 1;
}

footer {
  background-color: var(--col-dark);
  color: var(--col-light);
}
`