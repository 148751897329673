import { useState, useEffect } from 'react'

function useScrollPosition() {
  const [scrollPostion, setScrollPosition] = useState({
    x: window.scrollX,
    y: window.scrollY
  })
  useEffect(() => {
    function handleScroll() {
      setScrollPosition({
        x: window.scrollX,
        y: window.scrollY
      })
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return scrollPostion
}

export default useScrollPosition