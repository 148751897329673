const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
}

const mediaQuery = breakpoint => `@media (min-width: ${breakpoint}px)`

export {
  breakpoints,
  mediaQuery
}