import React from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import 'dist/css/bootstrap-grid.min.css'
import 'dist/css/bootstrap-utilities.min.css'
import 'dist/css/bootstrap-reboot.min.css'
import 'assets/fonts/Betterlove.ttf'
import 'assets/fonts/CaviarDreams.ttf'
import 'index.css'
import App from 'App'
// import reportWebVitals from 'reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
