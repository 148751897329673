import React from 'react'
import styled from 'styled-components'
import { FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import logo from 'assets/common/logo-wide.svg'
import { breakpoints, mediaQuery } from 'utils/breakpointsHelper'

const Menu = ({ display, setDisplay }) => {
  const className = display ? 'show-menu' : null
  return !display ? null : (
    <StyledMenu id='menu' className={ className }>
      <div className='container'>
        <div className='row menu-actions'>
          <div className='col-6 offset-3 d-flex justify-content-center align-items-center'>
            <img src={ logo } alt='' />
          </div>

          <div className='col-3 d-flex justify-content-end align-items-center'>
            <button id='menu-close-btn' className='d-flex align-items-center p-2' onClick={ () => setDisplay(false) }>
              <FaTimes />
            </button>
          </div>
        </div>
        <div className='menu-links d-flex flex-column align-items-center'>
          <Link onClick={ () => setDisplay(false) } className='fs-5' to='/'>Accueil</Link>
          <Link onClick={ () => setDisplay(false) } className='fs-5' to='/rendez-vous'>Prendre rendez-vous</Link>
          <Link onClick={ () => setDisplay(false) } className='fs-5' to='/presentation'>Présentation</Link>
          <Link onClick={ () => setDisplay(false) } className='fs-5' to='/collections'>Nos collections</Link>
          <Link onClick={ () => setDisplay(false) } className='fs-5' to='/verriers-partenaires'>Nos verriers partenaires</Link>
        </div>
      </div>
    </StyledMenu>
  )
}

export default Menu

const StyledMenu = styled.div`
position: fixed;
left: 0;
right: 0;
top: 0;
height: 100vh;
background-color: var(--col-light);
z-index: 2;
opacity: 0;

&:not(.show-menu) {
  pointer-events: none;
}
&.show-menu {
  opacity: 1;
}

.menu-actions {
  height: 60px;
  
  img {
    width: 50vw;

    ${mediaQuery(breakpoints.sm)} {
      width: 200px;
    }
  }

  #menu-close-btn {
    cursor: pointer;
    font-size: 1em;
    height: 40px;

    border: none;
    background: none;
    border-radius: 50%;
    background-color: rgba(0,0,0,0);
    transition: background-color 250ms ease;

    &:hover {
      background-color: rgba(0,0,0,0.1);
    }
  }
}

.menu-links {
  margin-top: 32px;
  a {
    font-weight: 300;
    padding: 12px;

    &:hover {
      font-weight: bold;
      color: var(--col-teal);
    }
  }
}
`