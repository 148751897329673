import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { FaBars } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import Menu from './Menu'
import logo from 'assets/common/logo.svg'
import useScrollPosition from 'hooks/useScrollPosition'
import { breakpoints, mediaQuery } from 'utils/breakpointsHelper'

const Header = () => {
  const [displayMenu, setDisplayMenu] = useState(false)
  const headerRef = useRef();
  const scrollPosition = useScrollPosition()
  
  const opacity = scrollPosition.y > 0 ? 1 : 0;

  return (
      <StyledHeader className='d-flex align-items-center' ref={ headerRef } opacity={ opacity }>
        <div className='container'>
          <div className='row'>
            <div className='col-3 d-flex align-items-center'>
              {/* <button onClick={ onClickImagesSizes }>images</button> */}
            </div>
            <div className='col-6 d-flex justify-content-center align-items-center'>
              <Link to='/'>
                <img id='logo' src={logo} alt="Logo de l'écrin à lunettes" />
              </Link>
            </div>

            <div className='col-3 d-flex justify-content-end align-items-center'>
              <button id='menu-btn' className='d-flex align-items-center p-2' onClick={ () => setDisplayMenu(!displayMenu) } aria-label='Open menu'>
                <FaBars />
              </button>
            </div>
          </div>
        </div>
        
        <Menu display={ displayMenu } setDisplay={ setDisplayMenu } />
      </StyledHeader>
  )
}

export default Header

const StyledHeader = styled.header`
background-color: rgba(255,255,255, ${p => p.opacity});
position: fixed;
left: 0;
top: 0;
right: 0;
height: 50px;
z-index: 2;

${mediaQuery(breakpoints.sm)} {
  height: 60px;
}

transition: background-color 1000ms ease-out;

#logo {
  width: 58px;
  height: 20px;

  ${mediaQuery(breakpoints.sm)} {
    width: 87px;
    height: 30px;
  }
}

#menu-btn {
  cursor: pointer;
  font-size: 1em;
  line-height: 1.3em;

  border: none;
  background: none;
  border-radius: 50%;
  background-color: rgba(0,0,0,0);
  transition: background-color 250ms ease;

  &:hover {
    background-color: rgba(0,0,0,0.1);
  }
}
`