import React, { Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router'
import styled from 'styled-components'
import { breakpoints, mediaQuery } from 'utils/breakpointsHelper'

const HomePage = lazy(() => import('components/pages/home/HomePage'))
const CollectionsPage = lazy(() => import('components/pages/collections/CollectionsPage'))
const CollectionPage = lazy(() => import('components/pages/collections/CollectionPage'))
const GlassmakersPage = lazy(() => import('components/pages/glassmaker/GlassmakersPage'))
const GlassmakerPage = lazy(() => import('components/pages/glassmaker/GlassmakerPage'))
const AppointmentPage = lazy(() => import('components/pages/appointment/AppointmentPage'))
const PresentationPage = lazy(() => import('components/pages/presentation/PresentationPage'))
const LegalMentionPage = lazy(() => import('components/pages/legal/LegalMentionPage'))

const Page = () => (
  <StyeldPage>
    <Suspense fallback={ <div>Loading...</div> }>
      <Switch>
        <Route exact path='/' component={ HomePage } />
        <Route exact path='/rendez-vous' component={ AppointmentPage } />
        <Route path='/presentation' component={ PresentationPage } />
        <Route exact path='/collections' component={ CollectionsPage } />
        <Route path='/collections/:collection' component={ CollectionPage } />
        <Route exact path='/verriers-partenaires' component={ GlassmakersPage } />
        <Route path='/verriers-partenaires/:glassmaker' component={ GlassmakerPage } />
        <Route exact path='/mentions-legales' component={ LegalMentionPage } />
      </Switch>
    </Suspense>
  </StyeldPage>
)

// Longchamp, Chloe, Gucci, Lacoste et Tommy Hilfiger

export default Page

const StyeldPage = styled.main`
margin-top: 50px;
${mediaQuery(breakpoints.sm)} {
  margin-top: 60px;
}
`