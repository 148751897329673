import React from 'react'
import styled from 'styled-components'
import { FaFacebookF, FaInstagram } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Footer = () => (
  <StyledContainer className='mt-5'>
    <div className='container pt-5 pb-3'>
      <div className='row'>
        <div className='col-12 col-sm-3'>
          <section className='d-flex flex-column'>
            <div className='title mb-2'>L'écrin à lunettes</div>
            <ul>
              <li><Link to='/presentation'>Le magasin</Link></li>
            </ul>
          </section>
        </div>
        <div className='col-12 col-sm-3'>
          <section className='d-flex flex-column'>
            <div className='title mb-2'>Partenaires</div>
            <ul>
              <li><Link to='/collections'>Lunettes</Link></li>
              <li><Link to='/verriers-partenaires'>Verres</Link></li>
              <li><Link to='/presentation/adaptation-lentilles'>Lentilles</Link></li>
            </ul>
          </section>
        </div>
        <div className='col-12 col-sm-3'>
          <section className='d-flex flex-column'>
            <div className='title mb-2'>Service client</div>
            <ul>
              <li><Link to='/rendez-vous'>Prendre rendez-vous</Link></li>
            </ul>
          </section>
        </div>
        <div className='col-12 col-sm-3'>
          <section className='d-flex flex-column'>
            <div className='title mb-2'>Réseaux sociaux</div>
            <ul className='d-flex'>
              <li><a target='_blank' rel='noopener noreferrer' className='social-link' href='https://www.facebook.com/Lecrinalunettes' title='Facebook'><FaFacebookF /></a></li>
              <li><a target='_blank' rel='noopener noreferrer' className='social-link' href='https://www.instagram.com/lecrinalunettes' title='Instagram'><FaInstagram /></a></li>
            </ul>
          </section>
        </div>
      </div>
      <hr />
      <div className='contact'>
        1 avenue Keller<br />
        25400 Arbouans<br />
        <div className=''><a href='tel:+33339300188'>03.39.30.01.88</a></div>
      </div>
      <div className='row mt-3'>
        <div className='col legal'>
          <Link to='/mentions-legales'>Mentions légales</Link> - © L'écrin à lunettes { new Date().getFullYear() }
        </div>
      </div>
    </div>
  </StyledContainer>
)

export default Footer

const StyledContainer = styled.footer`
background-color: var(--col-dark);
color: var(--col-light);
text-transform: uppercase;

section {
  margin-bottom: 20px;

  .title {
    font-size: 1.3em;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 4px 0;
      font-size: .7em;
      a {
        color: var(--col-light);
        text-decoration: none;
        font-weight: 400;
        
        &.social-link {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 42px;
          height: 42px;
          font-size: 1.5em;
          padding: 8px;
          margin-top: -8px;
          border: solid 1px var(--col-dark);
          &:hover {
            border: solid 1px var(--col-light);
          }
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.contact {
  font-size: .9em;
  a {
    color: var(--col-teal-1);
  }
}

.legal {
  font-size: .7em;
  
  a {
    text-decoration: none;
    text-transform: none;
    color: var(--col-light);

    &:hover {
      text-decoration: underline;
    }
  }
}
`